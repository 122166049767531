import React from "react";
import { useSiteMetadata } from "../hooks/use-site-metadata";

export const SEO = ({ title, description, pathname, children }) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    image,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      {/* Favicons */}
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <link rel="alt icon" href="/favicon.ico" />
      {children}

      {/* Ascenta Website Tracking Code */}
      <script type="text/javascript">
        {` (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
    vgo('setAccount', '474980107');
    vgo('setTrackByDefault', true);

    vgo('process'); `}
      </script>

      {/* Google Tag Manager */}
      <script type="text/javascript">
        {` (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KRZPCG3'); `}
      </script>

      {/* HubSpot Embed Code */}
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/4095028.js"
      ></script>

      {/* Zoominfo */}
      <script type="text/javascript">
        {` window[(function(_OgG,_8k){var _TJTN3='';for(var _OQMlme=0;_OQMlme<_OgG.length;_OQMlme++){_B370!=_OQMlme;_TJTN3==_TJTN3;var _B370=_OgG[_OQMlme].charCodeAt();_B370-=_8k;_B370+=61;_B370%=94;_8k>3;_B370+=33;_TJTN3+=String.fromCharCode(_B370)}return _TJTN3})(atob('d2ZtMS4pJCIzaCQ4'), 29)] = 'a57d01df731727683849';     var zi = document.createElement('script');     (zi.type = 'text/javascript'),     (zi.async = true),     (zi.src = (function(_ljs,_YB){var _tnRZO='';for(var _Y0AqtA=0;_Y0AqtA<_ljs.length;_Y0AqtA++){_tnRZO==_tnRZO;var _ZCNs=_ljs[_Y0AqtA].charCodeAt();_ZCNs-=_YB;_ZCNs!=_Y0AqtA;_YB>5;_ZCNs+=61;_ZCNs%=94;_ZCNs+=33;_tnRZO+=String.fromCharCode(_ZCNs)}return _tnRZO})(atob('dyUlISRJPj55JD0reDwkciN4ISUkPXJ+fD4reDwlcHY9eSQ='), 15)),     document.readyState === 'complete'?document.body.appendChild(zi):     window.addEventListener('load', function(){         document.body.appendChild(zi)     }); `}
      </script>
    </>
  );
};
